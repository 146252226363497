<template>
  <div class="ModalBody" @click="(event) => event.stopPropagation()">
    <div class="Overlay"></div>
    <div class="ModalContent">
      <h5>¿Está seguro?</h5>
      <div class="textContainer">
        <p class="textContentModal">Este turno se va a dar por</p>
        <p class="textContentModal">culminado</p>
      </div>
      <div class="containerButtons">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBody",

  data() {
    return {};
  },
};
</script>

<style scoped>
.Overlay {
  position: fixed;
  z-index: 800000000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.42);
}

.ModalContent {
  width: 424px;
  height: 199px;
  text-align: center;
  position: fixed;
  z-index: 800000000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  /* BORDER 2 */

  border: 1px solid #e4eaee;
  /* DROP SHADOW */

  box-shadow: -8px 4px 45px rgba(112, 144, 176, 0.08);
  border-radius: 6px;
}

.ModalContent h5 {
  margin-top: 13px;
  padding-bottom: 13px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  /* MAIN COLOR */

  color: #183e55;
  border-bottom: 1px solid #e4eaee;
}

.textContentModal {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  /* Third color */

  color: #748996;
  margin: 0;
}

.textContainer {
  margin-top: 17px;
}

.containerButtons {
  margin-top: 19px;
}

.buttonGray {
  color: #748996;
  border: 1px solid #748996;
  border-radius: 6px;
  margin-right: 16px;
}

.buttonGreen {
  background: #07bfa7;
  border: 1px solid #07bfa7;
  color: #ffffff;
  border-radius: 6px;
}

.buttonGray,
.buttonGreen {
  width: 116px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
</style>
